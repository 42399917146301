/** @jsx jsx */
import { jsx, Flex, Text } from 'theme-ui'
import React from 'react'
import { graphql } from 'gatsby'
import Page from '../../components/liveStream'
import { liveAudioKSession } from '../../api_aws/streamAmg'
import heroBackground from '../../images/fgr-live-audio-hero.jpg'
import MetaData from '../../components/metadata'

const FGRLiveAudio = props => {
  return (
    <React.Fragment>
      <MetaData title="FGR Live Audio" />
      <Page
        {...props}
        pageData={props.data?.contentfulFgrLive}
        cta="Listen Now"
        background={heroBackground}
        title={
          <Flex
            sx={{
              justifyContent: 'center',
              position: 'relative',
              flexWrap: 'wrap',
            }}
            mb={3}
          >
            <Text color="primary" mr={2}>
              FGR Live
            </Text>{' '}
            Audio
          </Flex>
        }
        kSessionId={liveAudioKSession}
      />
    </React.Fragment>
  )
}

export const pageQuery = graphql`
  query FgrLiveAudio {
    contentfulFgrLive(title: { eq: "Audio" }) {
      subscribeArticle {
        title
        main {
          raw
          references {
            ...ArticleFragment
          }
        }
        aside {
          raw
          references {
            ...ArticleFragment
          }
        }
      }
    }
  }
`

export default FGRLiveAudio
